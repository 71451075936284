<template>
  <MasterDetail
    formTitle="Cadastro de Serviço"
    :cols="cols"
    :opts="opts"
    :customResource="customResource"
    :selectedResourceId="selectedResourceId"
    descriptionProperty="desc_servico"
    @onOpenFormDialog="onOpenFormDialog"
  >
    <span v-show="valorNFe" style="font-size:16px">
      <strong>Valor utilizado da nota:</strong>
      {{ valorTotalItens | toCurrency }}
      <small>de</small>
      {{ valorNFe | toCurrency }}
    </span>
  </MasterDetail>
</template>

<script>
import MasterDetail from "@/components/master-detail.vue";
import { mapGetters } from 'vuex';

export default {
  components: {
    MasterDetail,
  },
  computed: {
    ...mapGetters(['clientId']),
    criterioRateio: function () {
      return this.$route.query.criterio_rateio;
    },
    customResource: function () {
      return {
        ...this.resource,
        get: (param) => {
          return new Promise((resolve, reject) => {
            this.resource
              .get(param)
              .then((result) => {
                this.valorTotalItens = result.reduce((acc, { valor }) => acc + valor, 0);
                resolve(result);
              })
              .catch(reject);
          });
        },
      };
    },
    nfId: function () {
      return this.$route.params.nfId;
    },
    resource: function () {
      return this.apiResource(
        `/v1/fiscal/nfservico/${this.clientId}/${this.nfId}/itens`
      );
    },
    resourceProjetos: function () {
      return this.apiResource(
        `/v1/projetos/${this.clientId}/selecao`
      );
    },
    resourceUnidades: function () {
      return this.apiResource(`/v1/clientes/${this.clientId}/unidades`);
    },
    resourceNFe: function () {
      return this.apiResource(`/v1/fiscal/nfservico/${this.clientId}`).get({
          query: `nfId=${this.nfId}`,
        });
    },
    selectedResourceId: function () {
      return this.$route.query.itemId;
    },
    valorNFe: function () {
      return this.nf ? this.nf.valor_nf : 0;
    },
    cols: function () {
      const columns = [
        {
          key: "id",
          name: "ID",
          align: 1,
          hideInform: true,
        },
        {
          key: "projetoId",
          name: "Projeto",
          type: this.$fieldTypes.AUTOCOMPLETE,
          rel: { to: "projetos", key: "id", name: "titulo" },
          valueChanged: (value) => this.setFinalidades(value),
        },
        {
          key: "trabalho",
          name: "Benefício",
          type: this.$fieldTypes.AUTOCOMPLETE,
          rules: [{ rule: "required" }],
          rel: { to: "servicos", key: "value", name: "text" },
          editable: this.projetoSelecionado.trabalho == 3,
          valueChanged: (value) => (this.servicoDoProjetoSelecionado = value),
          colSize: 6,
        },
        {
          key: "unidadeId",
          name: "Unidade",
          type: this.$fieldTypes.AUTOCOMPLETE,
          rules: [{ rule: "required" }],
          rel: { to: "unidades", key: "id", name: "unidade" },
          colSize: 6,
        },
        {
          key: "finalidade",
          name: "Classificação",
          type: this.$fieldTypes.AUTOCOMPLETE,
          rules: [{ rule: "required" }],
          rel: {
            to: "todasfinalidades",
            key: "value",
            name: "value",
          },
          colSize: ['1', '2'].includes(this.criterioRateio) ? 6 : 12,
        },
        {
          key: "horas_ped",
          name: "Horas P&D (decimal)",
          type: this.$fieldTypes.MONEY2,
          hideInform: this.criterioRateio != "1",
          hideInTable: this.criterioRateio != "1",
          colSize: 6,
        },
        {
          key: "alocacao",
          name: "Alocação (%)",
          type: this.$fieldTypes.NUMBER,
          hideInform: this.criterioRateio != "2",
          hideInTable: this.criterioRateio != "2",
          colSize: 6,
        },
        {
          key: "desc_servico",
          name: "Descrição",
          type: this.$fieldTypes.TEXT,
          rules: [{ rule: "required" }],
        },
        {
          key: "valor",
          name: "Valor (calculado)",
          type: this.$fieldTypes.MONEY,
          nowrap: true,
          rules: [{ rule: "required" }],
          editable: false,
          hideInform: true,
        },
        {
          key: "justificativa",
          name: "Justificativa",
          type: this.$fieldTypes.TEXT,
        },
        {
          key: "atividade",
          name: "Atividade",
          type: this.$fieldTypes.TEXT,
          hideInform: this.servicoDoProjetoSelecionado === 1,
        },
        {
          key: "dataIni",
          name: "Data Início",
          type: this.$fieldTypes.DATE,
          colSize: 6,
          hideInform: this.servicoDoProjetoSelecionado === 1,
        },
        {
          key: "dataFim",
          name: "Data Fim",
          type: this.$fieldTypes.DATE,
          colSize: 6,
          hideInform: this.servicoDoProjetoSelecionado === 1,
        },
        {
          key: "criado_em",
          name: "Criado Em",
          type: this.$fieldTypes.DATETIME,
          hideInform: true,
        },
        {
          key: "modificado_em",
          name: "Modificado Em",
          type: this.$fieldTypes.DATETIME,
          hideInform: true,
        },
      ];

      return columns;
    },
  },
  created: function () {
    this.resourceUnidades.get().then(response => {
      this.opts.unidades = response;
    });
    this.resourceNFe.then(response => {
      this.nf = response;
      const ano = response.competencia.substring(0, 4);
      const query = `ano=${ano}`;
      this.resourceProjetos.get({ query }).then((response) => {
        this.opts.projetos = response.projetos;
      });
    });
  },
  methods: {
    onOpenFormDialog: function (selectedItem) {
      this.setFinalidades(selectedItem.projetoId);
      this.projetoSelecionado = selectedItem;
    },
    setFinalidades: function (projetoId) {
      const projeto = this.opts.projetos.find((p) => p.id == projetoId);
      this.opts.finalidades = this.opts.todasfinalidades.filter((f) =>
        f.trabalho.includes(projeto ? projeto.trabalho : 0)
      );
      this.servicoDoProjetoSelecionado = projeto ? projeto.trabalho : null;
      this.projetoSelecionado.trabalho = projeto ? projeto.trabalho : null;
    },
  },
  data: function () {
    return {
      servicoDoProjetoSelecionado: 0,
      valorTotalItens: 0,
      projetoSelecionado: {},
      nf: null,
      opts: {
        projetos: [],
        finalidades: [],
        unidades: [],
        todasfinalidades: [
          { value: "OBRAS CIVIS", trabalho: [2, 3] },
          { value: "SERVIÇOS TÉCNICOS", trabalho: [1, 2, 3] },
          { value: "TREINAMENTOS", trabalho: [1, 2, 3] },
          { value: "VIAGENS", trabalho: [1, 2, 3] },
          { value: "OUTROS CORRELATOS", trabalho: [2, 3] },
          { value: "AUDITORIA INDEPENDENTE (2019)", trabalho: [2, 3, 0] },
          { value: "APOIO TÉCNICO", trabalho: [1, 2, 3] },
        ],
        servicos: [
          { text: "LEI DO BEM", value: 1 },
          { text: "LEI DE INFORMÁTICA", value: 2 },
          { text: "AMBOS", value: 3 },
        ],
      },
    };
  },
};
</script>
